<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-equip
            label="설비"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="점검/정비실제기간"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :comboItems="typeItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="equipmentType"
            label="현황분류"
            v-model="searchParam.equipmentType"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비점검/정비완료 현황 목록"
      tableId="equipmentCd"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
    >
      <!-- 버튼 영역 -->
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'resultName'">
          <div v-if="props.row['resultName'] === '부적합'" class="description-td">
            {{ props.row['resultName'] }}
          </div>
          <div v-else>
            {{ props.row['resultName'] }}
          </div>
        </template>
        <template v-if="col.name === 'status_type_cd'">
          <q-chip
            :color="setTagColor1(props.row.status_type_cd)"
            :clickable="true"
            text-color="white"
          >
            {{setTagName1(props.row.status_type_cd)}}
          </q-chip>
        </template>
        <template v-if="col.name === 'equipment_cd_name'">
          <q-btn
            class="tableinnerBtn"
            flat
            :editable="editable"
            align="center"
            color="blue-6"
            :label="props.row[col.name]" />
            <br>
          <!-- <q-btn
            class="glossy"
            round 
            size="sm"
            :editable="editable"
            color="blue-6"
            icon="search"
            @click.stop="linkClick(props.row)" /> -->
        </template>
        <template v-if="col.name==='type_cd' && props.row.status_type_cd === 'Y'">
          <q-chip
            :color="getColors(props.row.type_cd)"
            :class="props.row.type_cd == 'WWPSC00000' ? 'blinking' : ''"
            text-color="white">
            {{props.row.type_name}}
          </q-chip>
        </template>
        <template v-if="col.name==='type_cd' && props.row.status_type_cd === 'N'">
          <q-chip
            :color="setTagColor(props.row.type_cd)"
            :class="props.row.type_cd ==='MCSC000020' ? 'blinking' : null"
            text-color="white"
            @click="btnClicked(props.row)">
            <q-avatar v-if="props.row.type_cd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
            {{setTagName(props.row.type_cd)}}
          </q-chip>
        </template>
      </template>
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "equip-check-status",
  data() {
    return {
      grid: {
        merge: [
          { index: 1, colName: "equipment_cd_name"},
        ],
        columns: [
          {
            name: "plant_name",
            field: "plant_name",
            label: "사업장",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
          {
            name: "equipment_cd_name",
            field: "equipment_cd_name",
            label: "설비명(관리번호)",
            style: 'width:150px',
            align: "center",
            sortable: false,
            type: "custom",
          },
          {
            name: "status_type_cd",
            field: "status_type_cd",
            label: "현황분류",
            style: 'width:50px',
            type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            name: "real_dept_name",
            field: "real_dept_name",
            label: "작업정비/점검부서",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "type_cd",
            field: "type_cd",
            label: "진행상태",
            style: 'width:100px',
            type: 'custom',
            align: "center",
            sortable: false,
          },
          {
            label: '점검/정비',
            align: 'center',
            sortable: false,
            child: [
              {
                name: "plan_dts_str",
                field: "plan_dts_str",
                label: "계획기간/일",
                style: 'width:80px',
                align: "center",
                sortable: false,
              },
              {
                name: "result_dts_str",
                field: "result_dts_str",
                label: "실제기간/일",
                style: 'width:80px',
                align: "center",
                sortable: false,
              },
              {
                name: "user_name",
                field: "user_name",
                label: "담당자",
                style: 'width:80px',
                align: "center",
                sortable: false,
              },
            ]
          },
        ],
        data: [],
      },
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      colorItems2: [
        { stepperMstCd: 'MCSC000005', stepperMstNm: '계획수립', colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: '점검중', colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: '점검완료', colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: '지연', colorClass: 'red' },
      ],
      searchParam: {
        plantCd: null,
        // 설비점검 start
        safCheckTypeCd1: null,
        // 설비정비 start
        safCheckTypeCd2: null, 
        resultCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
        relatedLawList: [],
        lawEquipmentFlag: null,
        equipmentType: null,
        year: '',
      },
      editable: true,
      period: [],
      type: '1',
      checkNoPlanItems: [
        { code: 'Y', codeName: '무계획' },
        { code: 'N', codeName: '계획' }
      ], // 점검진행상태
      typeItems: [
        { code: '2', codeName: '점검' },
        { code: '1', codeName: '정비' },
      ],
      colorItems: [
        { stepperMstCd: 'N', stepperMstNm: '점검', colorClass: 'green' },
        { stepperMstCd: 'Y', stepperMstNm: '정비', colorClass: 'orange' },
      ],
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sts.eck.equipcheck.url;
      // url setting
      // code setting
      // list setting
      // this.searchParam.year = this.$comm.getThisYear();
      this.searchParam.year = '2023';
      
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    btnCancel() {
      this.isResult = false;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems2) {
        let color = this.$_.find(this.colorItems2, { code: data }) || this.$_.find(this.colorItems2, { stepperMstCd: data })
        return color ?  color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems2) {
        let color = this.$_.find(this.colorItems2, { code: data }) || this.$_.find(this.colorItems2, { stepperMstCd: data })
        return color ?  color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    setTagName1(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ?  color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    setTagColor1(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ?  color.colorClass : '';
      } else {
        return ''
      }
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWPSC00000': // 정비요청
          returnColor = 'grey-6';
          break;
        case 'WWPSC00001': // 작업계획중
          returnColor = 'orange';
          break;
        case 'WWPSC00002': // 작업계획 승인중
          returnColor = 'blue';
          break;
        case 'WWPSC00004': // 작업결과 승인중
          returnColor = 'yellow';
          break;
        case 'WWPSC00003': // 작업진행중
          returnColor = 'deep-purple-6';
          break;
        case 'WWPSC00009': // 작업완료
          returnColor = 'green';
          break;
        case 'WWPSC99999': // 작업취소
        case 'WWPSC99998': // 작업계획취소
          returnColor = 'red-6';
          break;
      }

      return returnColor;
    },
  },
};
</script>
