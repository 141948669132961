var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-equip", {
                  attrs: { label: "설비", type: "search", name: "equipmentCd" },
                  model: {
                    value: _vm.searchParam.equipmentCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "equipmentCd", $$v)
                    },
                    expression: "searchParam.equipmentCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    name: "period",
                    label: "점검/정비실제기간",
                    defaultStart: "-6M",
                    defaultEnd: "6M",
                  },
                  model: {
                    value: _vm.period,
                    callback: function ($$v) {
                      _vm.period = $$v
                    },
                    expression: "period",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    comboItems: _vm.typeItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "equipmentType",
                    label: "현황분류",
                  },
                  model: {
                    value: _vm.searchParam.equipmentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "equipmentType", $$v)
                    },
                    expression: "searchParam.equipmentType",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "설비점검/정비완료 현황 목록",
            tableId: "equipmentCd",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "resultName"
                    ? [
                        props.row["resultName"] === "부적합"
                          ? _c("div", { staticClass: "description-td" }, [
                              _vm._v(
                                " " + _vm._s(props.row["resultName"]) + " "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                " " + _vm._s(props.row["resultName"]) + " "
                              ),
                            ]),
                      ]
                    : _vm._e(),
                  col.name === "status_type_cd"
                    ? [
                        _c(
                          "q-chip",
                          {
                            attrs: {
                              color: _vm.setTagColor1(props.row.status_type_cd),
                              clickable: true,
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.setTagName1(props.row.status_type_cd)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  col.name === "equipment_cd_name"
                    ? [
                        _c("q-btn", {
                          staticClass: "tableinnerBtn",
                          attrs: {
                            flat: "",
                            editable: _vm.editable,
                            align: "center",
                            color: "blue-6",
                            label: props.row[col.name],
                          },
                        }),
                        _c("br"),
                      ]
                    : _vm._e(),
                  col.name === "type_cd" && props.row.status_type_cd === "Y"
                    ? [
                        _c(
                          "q-chip",
                          {
                            class:
                              props.row.type_cd == "WWPSC00000"
                                ? "blinking"
                                : "",
                            attrs: {
                              color: _vm.getColors(props.row.type_cd),
                              "text-color": "white",
                            },
                          },
                          [_vm._v(" " + _vm._s(props.row.type_name) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  col.name === "type_cd" && props.row.status_type_cd === "N"
                    ? [
                        _c(
                          "q-chip",
                          {
                            class:
                              props.row.type_cd === "MCSC000020"
                                ? "blinking"
                                : null,
                            attrs: {
                              color: _vm.setTagColor(props.row.type_cd),
                              "text-color": "white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.btnClicked(props.row)
                              },
                            },
                          },
                          [
                            props.row.type_cd === "MCSC000020"
                              ? _c("q-avatar", {
                                  attrs: {
                                    icon: "alarm",
                                    color: "red",
                                    "text-color": "white",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(_vm.setTagName(props.row.type_cd)) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "table-button" }, [
            _c(
              "div",
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      attrs: { label: "검색", icon: "search" },
                      on: { btnClicked: _vm.getList },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }